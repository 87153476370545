import React from 'react';

const IconKaggle = () => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <clipPath id="a">
      <path d="m31.5 15.978h289v130.044h-289z" />
    </clipPath>
    <path
      clipPath="url(#a)"
      d="m105.75 102.968c-.06.238-.298.357-.713.357h-7.937c-.477 0-.89-.208-1.248-.625l-13.106-16.672-3.655 3.477v12.93c0 .595-.298.892-.892.892h-6.152c-.595 0-.892-.297-.892-.892v-58.935c0-.593.297-.89.892-.89h6.153c.594 0 .892.298.892.89v36.288l15.692-15.87c.416-.415.832-.624 1.248-.624h8.204c.356 0 .593.15.713.445.12.357.09.624-.09.803l-16.585 16.046 17.297 21.488c.237.238.297.535.18.892"
      transform="matrix(.527027 0 0 .527027 -30.632288 -22.45559)"
    />
  </svg>
);

export default IconKaggle;
